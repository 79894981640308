import * as React from "react";
import { Button } from "src/components/button";
import { SpaceListener } from "src/components/spaceListener";
import "src/styles/game.css"

export interface GameRoundResultsView {
	didUserGuess: boolean;
	isCountryCorrect: boolean;
	correctCountryText: string;
	userCountryText: string;
	distanceText: string;
	score: number;
	onNextRound: () => void;
	isFinalRound: boolean;
}

export function GameRoundResultsView(props: GameRoundResultsView) {
	return (
		<div className="game-map-results">
			{props.didUserGuess ? (
				<>
					{props.isCountryCorrect ? (
						<>
							<span className="success-guess">Correct!</span>
							{`It was ${props.correctCountryText}!`}
						</>
					) : (
						<>
							<span className="failed-guess">Incorrect!</span>
							{`You guessed ${props.userCountryText} but it was ${props.correctCountryText}.`}
						</>	
					)}
					<br />
					<span>You were <b className={props.isCountryCorrect ? "highlight":""}>{props.distanceText}</b> away.</span>
				</>
			): (
				<span className="didnt-guess">You did not make a guess in time. The correct country was {props.correctCountryText}.</span>
			)}
			
				{props.score != null && props.score !== 0 ? <span>You scored <b className={props.isCountryCorrect ? "highlight":""}>{props.score}</b> points.</span> : (props.didUserGuess && <span>You did not score any points.</span>)}
			
			<div className={`button-wrapper-cool ${props.isFinalRound ? "finish-game" : "next-round"}-button ${props.didUserGuess ? "":"button-didnt-guess"}`}>
				<Button onClick={props.onNextRound} >
					{props.isFinalRound ? "Finish game" : "Next round"}
				</Button>
			</div>
			<SpaceListener onSpacePressed={props.onNextRound}/>
		</div>
	)
}
