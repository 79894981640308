import * as React from "react";
import {NavbarView} from "src/views/navbarView";
import {SessionProps} from "src/model/model";

export function Navbar({session}: SessionProps) {
	return <NavbarView
		userHandler={session.userModel.userHandler}
		onAccountClick={(signUp) => {
			session.auth.setSignUp(signUp);
		}}
	/>
}
