/// <reference types="vite-plugin-svgr/client" />
import ProfileSVG from "src/svg/profile.svg?react";
import * as React from "react";
import {hueFromId} from "src/modules/colorUtil";

import "src/styles/profile.css";

export interface ProfileProps extends React.BaseHTMLAttributes<HTMLDivElement> {
	id: string
	username: string;
	host?: boolean;
	you?: boolean;
	removeUsernameOnMobile?: boolean;
}
export function Profile({id, username, host = false, you = false, removeUsernameOnMobile = false, className, ...props}: ProfileProps) {
	return <div className={`profile ${host ? "host " : ""}${you ? "you " : ""}${className ? className : ""}`} {...props}>
		<div className="profile-picture">
			<ProfileSVG style={{
				backgroundColor: `hsl(${hueFromId(id)}, 80%, 50%)`
			}} />
		</div>
		<span className={removeUsernameOnMobile ? "hide-on-mobile" : ""}>{username}</span>
	</div>
}
