import * as React from "react";
import {observable, reaction, configure} from "mobx";
import {createRoot} from "react-dom/client";
import {Session} from "src/model/model";
import {BrowserRouter} from "react-router-dom"
import {Root} from "src/root";
import {connectToFirebase} from "./modules/firebaseApi";

import "src/styles/style.css";

// Configuring MobX to always enforce actions, since we are using MobX with actions.
configure({
    enforceActions: "always",
    computedRequiresReaction: true,
    reactionRequiresObservable: true,
    //observableRequiresReaction: true,
    //disableErrorBoundaries: true
})

const reactiveSession = observable(new Session());

import {PromiseHandler} from "src/model/promiseHandler";
const davesModel = new PromiseHandler();

createRoot(document.getElementById('root')!).render(
	<BrowserRouter future={{
		v7_startTransition: true, // boilerplate to get rid of warnings.
		v7_relativeSplatPath: true
	}}>
		<Root
			session={reactiveSession}
		/>
	</BrowserRouter>
);

connectToFirebase(reactiveSession, reaction);