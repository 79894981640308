import * as React from "react";
import {GameData} from "src/model/model";
import {GameEndView} from "src/views/game/gameEndView";
import {GameEndMultiView} from "src/views/game/gameEndMultiView"
import {Round} from "src/model/roundModel";
import {observer} from "mobx-react-lite";
import { on } from "events";

export interface GameEndProps extends GameData {
	onRestart: () => void;
}
export const GameEnd = observer(({game, user, onRestart}: GameEndProps) => {
	/* idk where to put these probably in the model */
	function scoreReducerCB(points, userId: string) {
		points[userId] = game.getTotalScore(userId)
		console.log("POINTS", game.getTotalScore(userId))
		return points;
	}

	const scores= Object.keys(game.userNames).reduce(scoreReducerCB, {})

	function onChangeRoundViewACB(e){	// TODO: Let users select a round to view everyone's scores on that round
		//console.log(e);
	}
	if (Object.keys(game.userNames).length > 1) {
		return <GameEndMultiView 
		userNames={game.userNames}
		scores={scores}
		onChangeRoundView={onChangeRoundViewACB}
		restartGame={onRestart}
		
		
	/>
	} else {
		return <GameEndView
		score={game.getTotalScore(user.id)}
		restartGame={onRestart}
		onChangeRoundView={onChangeRoundViewACB}
	/>;
	}



})
