/// <reference types="vite-plugin-svgr/client" />
import Logo from "src/svg/logo.svg?react"; // Imports the SVG as a component: https://stackoverflow.com/a/70314031/13347795
import * as React from "react";
import "/src/styles/home.css";
import {Button} from "src/components/button";
import {Link} from "react-router-dom";

export function Home() {

	return (
	<main id="home" className="main-home animation-wrapper-load">
		<div className="welcome-container">
			<h1 className="welcome-title"><span className="logo-container"><Logo className="logo-svg"/>YouGuessr</span></h1>
			<p className="welcome-subtitle">Guess the country behind the YouTube video!</p>
			<p className="header-subtitle">
				YouGuessr is a game that challenges your knowledge of cultures, languages and geography. 
				You will be shown a YouTube video from somewhere in the world, and your goal is to figure out where it comes from!
			</p>
			<div className="button-wrapper-cool button-play-now">
				<Link to="/game">
					<Button>
						PLAY NOW
					</Button>
				</Link>
			</div>
		</div>

		<hr/>
		<h2 id="how-to-play">How to play?</h2>
		<div className="home-how-to-container">
			<p>You will be shown a YouTube video on the left side of the screen.</p>
			<img className="home-how-to-image" src="/assets/howToPlay1.webp"/>
			<p>Using clues like the video's content or title, figure out where in the world the video might be from.</p>
			<img className="home-how-to-image" src="/assets/howToPlay2.webp"/>
			<p>Once you think you've figured it out, select the location by clicking on the map to the right.</p>
			<img className="home-how-to-image" src="/assets/howToPlay3.webp"/>
			<p>You can then make your guess by clicking the "Confirm guess" button!</p>
			<img className="home-how-to-image" src="/assets/howToPlayGuess.webp" />
			<p>You will receieve points based on how close your guess is to the correct location. Earn <b>bonus points</b>  for guessing the exact country!</p>
			<img className="home-how-to-image" src="/assets/howToPlay4.webp"/>
			{/* <p> Now you are ready to become the YouGuessr!</p> */}
			
		</div>	
		
		

		{/* <p className="small-text align-center">Note: Some videos may have been uploaded from a different location than they were recorded, which will make it very challeging to guessr correctly.</p> */}

	</main>
)}
