import {Round} from "./roundModel";
import {Persistable} from "./persistable";
import {makeObservable, observable, action, computed} from "mobx";
import {v4 as uuidv4} from "uuid";
import {getCountryFromCoordinates, getRandomCountryAndPosition} from "src/modules/mapApi";
import {getVideoFromCoordinates} from "src/modules/youtubeApi";
import L from "leaflet";

/** Enum representing round lengths. The enum values are float numbers that can be multiplied by the usual round length, except for Infinite which is represented by 0 and must be handled separately. */
export enum RoundLength { // These numbers are multiplited by the original round durations.
	Short = 1,
	Normal = 2.,
	Long = 4.,
	Infinite = 0. // Edge case, must be handled separately.
}

/** Object storing the rules in a game */
export class GameRules implements Persistable {
	/** Integer representing the total number of rounds in this game. */
	@observable numberOfRounds: number;
	/** Enum representing how long the rounds of the game are. See {@link RoundLength}. */
	@observable roundLength: RoundLength = RoundLength.Normal;

	dbReady: boolean;

	@action
	setNumOfRounds(input:number){
		this.numberOfRounds = input;
	}

	@action
	setRoundLength(input:RoundLength){
		this.roundLength = input;
	}

	setReady(input: boolean) {
		this.dbReady = input;
	}

	@computed get roundLengthMilliseconds() {
		return this.roundLength * 15000;
	}

	modelToDb() {
		return {
			numberOfRounds: this?.numberOfRounds,
			roundLength: this?.roundLength.valueOf(),
		} 
	}
	
	dbToModel(dbData: any) {
		this.setNumOfRounds(dbData?.numberOfRounds || this.numberOfRounds || 3);
		// TODO: Using `as RoundLength` is unsafe and should maybe be replaced with a better method.
		this.setRoundLength(dbData?.roundLength != null ? dbData.roundLength as RoundLength : this.roundLength);
	}

	constructor(){
		makeObservable(this)
	}
}

/** Object containing all information about the game being set up or in progress. */
export class Game implements Persistable {
	/** Unique identifier for the game. */
	@observable id: string;
	/** ID of the user hosting the game. */
	@observable hostUserId: string;
	/** ID of the currently logged in user.
	 * NOT SYNCED TO FIREBASE.
	 */
	readonly currentUserId: string;
	/** An array containing the IDs of each participating user, including the host. */
	@observable userIds: string[];
	/** A dictionary of all user names, with their respective user ID as key.
	 * NOT SYNCED TO FIREBASE.
	 */
	@observable userNames: {[userId: string]: string};
	/** An object containing the game rules. See {@link GameRules}. */
	@observable gameRules: GameRules;
	/** An array containing the data for each round of the game. See {@link Round}. */
	@observable rounds: Round[] = [];
	/** Index of current round in {@link Game["rounds"]}.
	 * if -1: game hasn't started yet.
	 * if number that is out of range for rounds array: game has finished.
	 * else: game is in progress.
	 */
	@observable roundIndex: number = -1;

	/* Used to check if the model is ready to receive data from the database. */
	dbReady: boolean;

	/** Called once when hasLoaded is set to true.
	 */
	initialLoadACB?: () => void;
	/** A bool that is false until the model has been properly synced to firebase.
	 * Used in combination with initialLoadACB to await initial firebase read.
	 */
	hasLoaded: boolean = false;

	/** Run once when the model has been synced to firebase to set hasLoaded treu. */
	finishLoad() {
		this.initialLoadACB?.();
		this.hasLoaded = true;
	}

	constructor(userId: string, userName, id: string = uuidv4(), gameRules: GameRules = new GameRules(), initialLoadACB?: () => void) {
		this.id = id;
		this.hostUserId = userId;
		this.currentUserId = userId;
		this.userIds = [userId];
		this.userNames = {[userId]: userName}
		this.gameRules = gameRules;
		this.initialLoadACB = initialLoadACB;
		makeObservable(this)
	}

	@action
	setRounds(rounds: Round[]) {
		this.rounds = rounds;
		this.roundIndex = 0;
	}

	/** Submits guess and ends round if everyone in the game has submitted. */
	submitGuess() {
		const round = this.rounds[this.roundIndex]
		round.submitGuess(this.currentUserId);
		if (Object.keys(round.guesses).length >= this.userIds.length) {
			round.viewResults();
		}
	}

	/** A tiny lil' thing that keeps track if the endRoundIn function is currently already running, and prevents it from running again if it already is. */
	private countingDown = false;
	@action
	private allowTimer() {
		this.countingDown = false;
	}

	/** Ends round after timeout (if current user is game host and round length isn't infinite), and submits guess if user hasn't done it already. */
	endRoundIn(ms: number, roundIndex: number = this.roundIndex) {
		const round = this.rounds[roundIndex]; // Needs to be defined here, so the correct round is referenced.
		if (
			this.countingDown ||
			round == null ||
			round.hasEnded ||
			this.gameRules.roundLength == RoundLength.Infinite ||
			this.currentUserId != this.hostUserId // BUG: If host user doesn't have the page open, the game won't end.
		) {
			return;
		}
		this.countingDown = true;
		setTimeout(() => {
			const round = this.rounds[roundIndex];
			this.countingDown = false;
			if (round.hasEnded) {
				return;
			}
			if (round.guesses[this.currentUserId] == null && round.userPosition != null) {
				round.submitGuess(this.currentUserId);
			}
			round.viewResults()
		}, ms);
	}

	@action
	/** This needed to be separate because actions cannot be asynchronous. */
	private actuallyStartGame(rounds: Round[]) {
		this.rounds = rounds;
		this.roundIndex = 0;
	}
	/** Will load all rounds and then start the game.*/
	async startGame() {
		const roundPromises: Promise<Round>[] = Array.from({length: this.gameRules.numberOfRounds}, async () => {
			const correctPosition = getRandomCountryAndPosition();
			const video = await getVideoFromCoordinates(correctPosition.position);
			const recordingDetails = video.recordingDetails;
			const videoPosition = L.latLng(recordingDetails.location.latitude, recordingDetails.location.longitude);

			correctPosition.position = videoPosition;
			const videoCountry = getCountryFromCoordinates(videoPosition);
			if (videoCountry) correctPosition.country = videoCountry;
			
			return new Round(video, correctPosition, this.currentUserId);
		})
		this.actuallyStartGame(await (Promise.all(roundPromises)));
		this.rounds[this.roundIndex].startRound();
		this.endRoundIn(this.gameRules.roundLengthMilliseconds)
		return this;
	}

	@action
	nextRound() {
		if (this.roundIndex+1 < this.gameRules.numberOfRounds) {
			this.rounds[this.roundIndex+1].startRound();
			this.allowTimer();
			this.endRoundIn(this.gameRules.roundLengthMilliseconds, this.roundIndex+1)
		}
		this.roundIndex++;
	}

	@action
	setReady(input: boolean) {
		this.dbReady = input;
	}
	
	getTotalScore(userId: string = this.currentUserId): number { // TODO: Maybe make @computed.
		return this.rounds.reduce((acc, round) => {
			const score = round.guesses[userId]?.score;
			return score ? acc + score : acc;
		}, 0)
	}

	/** A Date object showing the time when the round ends. Will be null if round length is infinite. */
	@computed get roundEndTime(): Date | null {
		const startTime = this.rounds[this.roundIndex]?.startTime;
		if (this.gameRules.roundLength == RoundLength.Infinite || startTime == null) {
			return null;
		}
		return new Date(startTime.getTime() + this.gameRules.roundLengthMilliseconds);
	}

	modelToDb() {
		return {
			gameId: this.id,
			gameHostUid: this.hostUserId,
			gameUids: this.userIds.reduce((acc, id) => {
				return {...acc, [id]: true};
			}, {}),
			gameUserNames: this.userNames,
			gameRoundIndex: this.roundIndex,
			gameRules: this.gameRules.modelToDb(), 
			gameRounds: this.rounds.map((round) => round.modelToDb()),
		}
	}

	@action
	dbToModel(dbData: any) {
		this.id = dbData?.gameId || this.id;
		this.hostUserId = dbData?.gameHostUid || this.hostUserId; // TODO: A bit scuffed, if there is no host this user becomes host.
		this.userIds = dbData?.gameUids ? Object.keys(dbData.gameUids) : this.userIds;
		this.roundIndex = dbData?.gameRoundIndex || dbData?.gameRoundIndex == 0 ? dbData?.gameRoundIndex : this.roundIndex;
		this.gameRules.dbToModel(dbData?.gameRules);

		//console.log("GAME ROUNDS", dbData?.gameRounds);
		if (dbData?.gameRounds != null) {
			const rounds = dbData.gameRounds.reduce((rounds: Round[], dbRound: any) => {
				const round = new Round(null, null, this.currentUserId);
				round.dbToModel(dbRound)
				return [... rounds, round];
			}, []);
			//console.log(rounds);
			this.rounds = rounds;
		}

		const updateUids = !this.userIds.includes(this.currentUserId)
		if (updateUids) {
			this.userIds = [...this.userIds, this.currentUserId];
		}
		if (dbData?.gameUserNames != null) {
			this.userNames = dbData.gameUserNames[this.currentUserId] != null ? dbData.gameUserNames : {
				...dbData.gameUserNames,
				[this.currentUserId]: this.userNames[this.currentUserId]
			}
		}

		// Makes sure that the round actually ends when the timer runs out.
		// TODO: Replace with this fancy new continueRound method I keep hearing good things about.
		const round = this.rounds[this.roundIndex]
		if (round != null && !round.hasEnded && this.gameRules.roundLength != RoundLength.Infinite) {
			this.endRoundIn(this.roundEndTime.getTime() - new Date().getTime())
		}
		return updateUids;
	}
}

import {PromiseHandler} from "./promiseHandler";

export class GameModel {
	/** Promise containing the current game. Use gameHandler.result to get the Game object whenever the promise resolves. See {@link Game} and {@link PromiseHandler}. */
	handler: PromiseHandler<Game> = new PromiseHandler();

	/** Creates promise for the game that resolves after the game has been correctly synced to firebase. */
	async getPromise(timeoutMS: number = 3000): Promise<Game | null> {
		const result = await this.handler.promise;
		if (result != null && !result.hasLoaded) { // If not hasLoaded, creates promise that resolves when hasLoaded is set and awaits it.
			const loadPromise = new Promise<void>((resolve, reject) => {
				/*const timeoutError = new Error("Database load timed out."); // Defined here, outside of timeout, to get full stack trace.
				const timeoutRef = setTimeout(() => {
					if (this.handler.result === result) { // Race condition prevention
						reject(timeoutError)
					}
				}, timeoutMS);*/
				result.initialLoadACB = () => {
					//clearTimeout(timeoutRef)
					resolve()
				};
			})
			await loadPromise;
		}
		return result;
	}

	/** Creates a new Game object that is also persisted in the model. */
	createGame(...gameParams: ConstructorParameters<typeof Game>): Game {
		const newGame = new Game(...gameParams);
		this.handler.setResult(newGame);
		return newGame;
	}

	joinGame(userId: string, userName: string, gameId: string) {
		const newGame = new Game(userId, userName, gameId);
		this.handler.setResult(newGame);
		return newGame;
	}

	@action
	restartGame(): Game {
		const oldGame = this.handler.result;
		this.handler.result.roundIndex = -1;
		const newGame = new Game(
			oldGame.currentUserId, // This technically means that whoever presses restart game will be the host. Don't know if that's what we want.
			oldGame.userNames[oldGame.currentUserId],
			oldGame.id, //undefined, // Generates new game id.
			oldGame.gameRules
		)
		this.handler.setResult(newGame); // Will this work for firebase? Is there a way to tell it to delete the old one and start a new one?
		// Sort of, but it would be better to implement another way of keeping track of what has happenend. ^^^
		return newGame;
	}
}
