import * as React from "react";
import YouTube from "react-youtube";

export interface GameRoundVideoViewProps {
	videoId: string;
	onVideoReady: (e) => void;
}
export function GameRoundVideoView(props: GameRoundVideoViewProps) {

	return <div className="game-video-wrapper">
		<YouTube
		className="youtube-wrapper"
		videoId={props.videoId} 
		opts={{
			playerVars: {
				playsinline: 1,
				iv_load_policy: 3,
				autoplay: 1,
				fs: 0
			}
		}	
		}
		onReady={props.onVideoReady}
		/>
	</div>
}

