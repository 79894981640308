import {observable, action, makeObservable} from "mobx";

/**
 * Handles a promise that will give a result of type T, just like in the lab.
 * Use the result attribute to get the contained item of type T whenever the promise resolves.
 */
export class PromiseHandler<T> {
	/**
	 * The promise to be resolved, set using the {@link handlePromise} method or the class constructor.
	 */
	@observable promise: Promise<T> | null; // TODO: Add a loading bool and make that observable instead of this.
	/**
	 * Will be null until {@link promise} resolves, then the result will be put here to be observed, just like in the lab.
	 */
	@observable result: T | null;
	/**
	 * If {@link promise} resolves into an error, then it will be put here instead.
	 */
	@observable error: any | null;

	@action
	private reset(promise: Promise<T> = null, result: T = null) {
		this.promise = promise;
		this.result = result;
		this.error = null;
	}

	@action
	private updateResult(result: T | null) {
		if (result == null){
			this.promise = null; // A null result will also make the promise null to signify missing data
		}
		this.result = result;
	}
	@action
	private setError(error: any) {
		this.error = error;
	}
	/** Updates the internal promise to a new one, and resets result and error until it resolves. */
	handlePromise(newPromise: Promise<T>) {
		this.reset(newPromise)
		if (newPromise == null) {
			return;
		}

		newPromise.then((result) => {
			if (this.promise !== newPromise) { // Prevents race condition.
				return;
			}
			this.updateResult(result);
		}).catch((error) => {
			console.error(error);
			if (this.promise !== newPromise) { // Prevents race condition.
				return;
			}
			this.setError(error);
		})
	}
	/** Updates the internal promise to one that resolves instantly with a new result. */
	setResult(result: T) {
		this.reset(new Promise((resolve) => resolve(result)), result);
	}

	constructor(promise: Promise<T> = null) {
		this.handlePromise(promise); // Resets result and error, and handles promise callback if provided.
		makeObservable(this);
	}
}
