import {CountryAndPosition, getCountryFromCoordinates, checkIsLatLngExtraregional} from "src/modules/mapApi"
import {Video} from "src/modules/youtubeApi";
import L from "leaflet";
import {makeObservable, observable, action} from "mobx";
import {calculateScore} from "src/modules/gameApi";

export interface Guess {
	position: L.LatLng;
	guessTime: number;
	score: number;
	distance: number;
	isInRightCountry: boolean;
}

export class Round {
	/** Timestamp of when the round started. */
	@observable startTime?: Date;
	@observable hasEnded: boolean = false;
	
	/** Dictionary containing information about how many points each player scored. */
	@observable guesses: {[userId: string]: Guess; } = {};

	/** */
	video: Video;

	/** */
	@observable userPosition?: CountryAndPosition;
	
	/** */
	correctPosition: CountryAndPosition;

	/** Used to see if the model is ready to receive data from the database. */
	dbReady: boolean;

	/** ID of the currently logged in user.
	 * NOT SYNCED TO FIREBASE.
	 */
	currentUserId: string;

	constructor(video: Video, correctPosition: CountryAndPosition, currentUserId: string) {
		this.video = video;
		this.correctPosition = correctPosition;
		this.currentUserId = currentUserId;
		makeObservable(this);
	}

	/**
	 * Set the position of the user-controlled marker 
	 * @param position - LatLng position to set the marker to
	 */
	@action
	setUserPosition(position: L.LatLng): void{
		this.userPosition = {
			position: position.wrap(),
			region: checkIsLatLngExtraregional(position),
			country: getCountryFromCoordinates(position)
		};
	}

	/** For the user, calculate the score, and time when guessed!
	 * ONLY RUN THIS FROM THE {@link Game} MODEL, NOT FROM THE PRESENTER!
	 * Use {@link Game.submitGuess} instead.
	 * @param userId - the Id of current user?
	 */
	@action
	submitGuess(userId: string) {
		const guessTime = new Date().getTime() - this.startTime.getTime();
		const distance = this.correctPosition.position.distanceTo(this.userPosition.position);
		const isInRightCountry = this.correctPosition.country?.name == this.userPosition.country?.name;
		let score = calculateScore(distance, isInRightCountry);

		this.guesses[userId] = {
			guessTime: guessTime,
			position: this.userPosition.position,
			distance: distance,
			isInRightCountry: isInRightCountry,
			score: score
		}
	}

	@action
	viewResults() {
		this.hasEnded = true;
	}

	@action
	startRound(){
		this.startTime = new Date();
	}
	
	setReady(input: boolean) {
		this.dbReady = input;	
	}

	createPositionFromDb(dbPosition: any) {
		if (dbPosition?.lat != null && dbPosition?.lng != null) {
			const latlng = new L.LatLng(dbPosition?.lat, dbPosition?.lng);
			return {
				position: latlng,
				region: dbPosition?.region || null,
				country: latlng ? getCountryFromCoordinates(latlng) : null 
			}
		} else {
			return null;
		}
		
		
	}

	modelToDb() {
		return {
			startTime: this.startTime != null ? this.startTime.getTime() : null,
			hasEnded: this.hasEnded,
			guesses: Object.keys(this.guesses).reduce((accumulator, uid) => {
				return {... accumulator, [uid]: {
					position: {
						lat: this.guesses[uid].position.lat,
						lng: this.guesses[uid].position.lng
					},
					guessTime: this.guesses[uid].guessTime,
					score: this.guesses[uid].score,
					distance: this.guesses[uid].distance,
					isInRightCountry: this.guesses[uid].isInRightCountry
				}}
			}, {}),
			video: {
				videoId: this.video.videoId
			},
			correctPosition: {
				lat: this.correctPosition?.position.lat || null,
				lng: this.correctPosition?.position.lng || null
			}
		};
	}

	@action
	dbToModel(dbData: any) {
		this.startTime = dbData?.startTime != null ? new Date(dbData.startTime) : this.startTime;
		this.hasEnded = dbData?.hasEnded != null ? dbData?.hasEnded : this.hasEnded;
		this.guesses = dbData?.guesses || this.guesses;
		this.video = dbData?.video || this.video;
		this.correctPosition = this.createPositionFromDb(dbData?.correctPosition) || this.correctPosition;
		this.userPosition = this.createPositionFromDb(dbData?.guesses?.[this.currentUserId]?.position ) || this.userPosition;
	};
}
