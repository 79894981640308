import * as React from "react";

import "src/styles/account.css";

export function AuthView(props: any) {
    return (
        <main id="auth" className="auth-container animation-wrapper-load">
            <div className="box">
                <h2>{props.signUp ? "Sign up" : "Log in"}</h2>
                <span className="need-to-be-logged-in">You need to be logged in to play.</span>

                <form onSubmit={values => props.submitForm(values)} id="accountForm">
                    { props.signUp && 
                        <>
                            <input placeholder="Username"/>
                            <br/>
                        </>
                    }
                    <input type="email" placeholder="Email"/>
                    <br/>
                    <input type="password" placeholder="Password"/>
                    <br/>
                    <input className="login" type="submit" value={props.signUp ? "Sign up" : "Log in"}/>
                </form>
            
                <hr className="line"/>

                {props.errorOccurred &&
                    <div className="error">
                        {props.errorMessage}
                    </div>
                }

                <br/>
                <span className="text-login-signup-switch">{props.signUp ? "Already have an account?" : "Don't have an account?"}</span><br/>
                <input type="button" onClick={() => props.toggleSignUp()} value={props.signUp ? "Log in to existing account" : "Create new account"}></input>
            </div>
        </main>
    );
};