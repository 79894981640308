import * as React from "react";
import { renderToString } from "react-dom/server"
import { MapContainer, TileLayer, useMapEvent, useMap, Pane, Polyline, GeoJSON } from 'react-leaflet'
import L from "leaflet";
import MapMarker from "src/components/mapMarker";

export interface MapViewProps {
    onMapClick: (latlng: L.LatLng) => void;
    onFinishZoomAnimation: () => void;
    onSoundEndClick: () => void;

    isInResults: boolean;

    markerPosition?: L.LatLng;
    countryName?: string;
    geoJson?: React.JSX.Element;

    correctPosition: L.LatLng;
    correctCountryName: string;
    correctCountryGeoJson?: React.JSX.Element;

    playerGuesses?: {position: L.LatLng, countryName: string}[];

    playClickSound: boolean;
}

export function MapView(props: MapViewProps) {

    /** Used for listening to clicks on the map */
    function ClickListener(): null{
        useMapEvent('click', (e) => {
            props.onMapClick(e.latlng);
        })
        return null
    }

    /** Used for animating the map when the user makes a guess */
    function MapController(): null{
        const map = useMap();
        if (props.isInResults && props.markerPosition){
            map.flyToBounds(
                L.latLngBounds([...(props.playerGuesses ? props.playerGuesses.map(guess => guess.position) : [props.markerPosition]), props.correctPosition]),
                {
                    duration: 0.5
                }
            )

            // // can be used down the line for UX things
            map.once("moveend", () => {
                props.onFinishZoomAnimation()
            })

        }
        return null
    }

    /** Play a click sound when the user clicks on the map */
    const clickNoise = new Audio("/audio/click.wav");
    React.useEffect(() => {
        if (props.playClickSound) {
            let sound = clickNoise.cloneNode(false) as HTMLAudioElement;
            sound.play();
            props.onSoundEndClick();
            sound.addEventListener("ended", e => {
                sound.remove()
            })
        }
    }, [props.playClickSound]
    )
    
    function ResultsPolyline({position}: {position: L.LatLng}){
        return <Polyline positions={[position, props.correctPosition]} dashArray={[4, 4]} color="black" weight={2}/>
    }

    L.Icon.Default.prototype.options.className = "map-custom-marker";

    // const userIcon = L.divIcon({
    //     className: 'user-icon',
    //     html: renderToString(<MarkerRed className="marker-red"/>),
    //     iconSize: [25, 41]
    // })

    return (
        <>
        <link rel="stylesheet" href="https://unpkg.com/leaflet@1.9.4/dist/leaflet.css" integrity="sha256-p4NxAoJBhIIN+hmNHrzRCf9tD/miZyoHS5obTRR9BMY=" crossOrigin=""/>
        <script src="https://unpkg.com/leaflet@1.9.4/dist/leaflet.js" integrity="sha256-20nQCchB9co0qIjJZRGuk2/Z9VM+kNiyxNV1lvTlZBo=" crossOrigin=""/>
        <MapContainer className={`map ${props.isInResults && "map-results"}`} center={[20.0, 0]} zoom={2} scrollWheelZoom={true} maxBounds={L.latLngBounds(L.latLng(-90,-240), L.latLng(90,240))}>
            <MapController/>
            
			<TileLayer
				attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
				url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
			/>
            <Pane name="geojson" style={{zIndex: 401}}>
                {props.geoJson}
                {props.isInResults && props.correctCountryGeoJson}
            </Pane>

            {/* If not in results */}

            {!props.isInResults && <ClickListener/>}
            {props.markerPosition && !props.isInResults && <MapMarker position={props.markerPosition} tooltipText={props.countryName}/>}

            {/* If in results */}

            {props.isInResults && <MapMarker position={props.correctPosition} tooltipText={props.correctCountryName}/>}
            { 
                props.isInResults 
                && (props.playerGuesses ?
                props.playerGuesses.map((playerGuess, index) => <ResultsPolyline position={playerGuess.position} key={index}/>) :
                props.markerPosition && <ResultsPolyline position={props.markerPosition}/>)
            }
            {
                props.isInResults && props.playerGuesses && props.playerGuesses.map((playerGuess, index) => {
                    return <MapMarker position={playerGuess.position} tooltipText={playerGuess.countryName} key={index}/>
                })
            }

		</MapContainer>
        </>
    )
}