import * as React from "react";
import {Timer} from "src/components/timer";
import {Button} from "src/components/button";
import "src/styles/game.css"
import {useNavigate} from "react-router-dom";

interface GameRoundStatusProperties {
	score: number;
	isHost: boolean;
	currentRound: number;
	totalRounds: number;
	endTime?: Date;
	paused?: boolean;
	onAbort: () => void;
}
export function GameRoundStatusView({score, currentRound, totalRounds, isHost, endTime = null, paused = false, onAbort}: GameRoundStatusProperties){
	const navigate = useNavigate();

	const handleAbortClick = () => {
        if (window.confirm("Do you want to abort the game?"))
            onAbort();
        }
    return <>
        <div className={`game-round-status${endTime == null ? " no-timer":""}`}>
            <div className="button-wrapper-cool button-abort-game">
				{isHost ?
					<Button onClick={handleAbortClick}>Abort<br />game</Button> :
					<Button onClick={() => navigate("/game")}>Leave<br />game</Button>
				}
				
			</div>
            <p> 
                <span className="game-round-status-score">{score}</span> 
                <br />
                <span className="game-round-status-total"> OF <span className="game-round-status-total-value">{totalRounds * 7500}</span> POINTS </span>
            </p>
            <p> 
                <span className="game-round-status-score">{currentRound}</span> 
                <br />
                <span className="game-round-status-total"> OUT OF <span className="game-round-status-total-value">{totalRounds}</span> ROUNDS </span>
            </p>
			{endTime != null && !paused &&
				<Timer endTime={endTime} />
			}
			
        </div>
    </>;
}
