import { observable, action, makeObservable } from "mobx";

/** Authentication Model */
export class AuthModel {
	/** Returns true if an error has occurred during authentication. */
    error = false;
    /** Boolean for switching between registration and login modes. */
    signUp = true;
    /** String containing the error message. */
    errorMessage = "";

    /** Sets the error to true or false */
    setError(bool: boolean) {
        this.error = bool;
    };

    /** Toggles the signUp boolean. */
    toggleSignUp() {
        this.signUp = !this.signUp;
        this.setError(false);
    };

    setSignUp(input: boolean) {
        this.signUp = input;
    }

    /** Sets the errorMessage string. */
    setErrorMessage(input: string) {
        this.errorMessage = input;
    };

    /** Function for showing the error to the user. */
    displayError(error: Error) {
        this.setError(true);
        this.setErrorMessage(error.message);
        console.error(this.errorMessage);
    }; 

    constructor() {
        makeObservable(this, {
            error: observable,
            signUp: observable,
            errorMessage: observable,

            setError: action,
            toggleSignUp: action,
            setSignUp: action,
            setErrorMessage: action,
            displayError: action
        });
    }
}

export const authModel = new AuthModel();