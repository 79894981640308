import * as React from "react";
import {Button} from "src/components/button";
import {SpaceListener} from "src/components/spaceListener";

import "src/styles/style.css";
import "src/styles/form.css";

export interface GameEndProps {
	score: number;
	restartGame: () => void;
	onChangeRoundView: (e) => void;
}
export function GameEndView(props: GameEndProps) {

	function onSubmitACB() {
		props.restartGame()
	}

	function changeRoundViewACB(e){
		props.onChangeRoundView(e.target.value);
	}

	return (
		<main className="animation-wrapper-load game-end-main">
			<h1>Results!</h1>
			{(props.score === 0) ? (
				<div style={{alignItems: "center"}}>
					Your score: <span className="failed-guess">{props.score} </span>
				</div>
			) : (
				<div style={{alignItems: "center"}}>
					Your score: <span className="success-guess">{props.score} </span>
				</div>
			)}
			
			<div className="button-wrapper-cool">
				<Button className="button-cool" onClick={onSubmitACB}>Return to Lobby</Button>
			</div>
			<SpaceListener onSpacePressed={onSubmitACB}/>
			{/* <input type="number" onChange={changeRoundViewACB}></input> */}
		</main>
	);
}
