import * as React from "react";
import {GameSetup} from "./game/gameSetupPresenter";
import {GameRound} from "./game/gameRoundPresenter";
import {GameEnd} from "./game/gameEndPresenter";
import {Error} from "src/views/errorView";
import {SessionProps, GameDataRedirect} from "src/model/model";
import {PromiseHandler} from "src/model/promiseHandler";
import {PromiseRenderer} from "src/components/promiseRenderer";
import {observer} from "mobx-react-lite";
import {useParams, useNavigate, useLocation} from "react-router-dom";

export const GamePresenter = observer((props: SessionProps) => {
	const gameRoundData = new PromiseHandler(props.session.getGameData(useParams()?.id));

	function ResultComponent({ result: {redirectRoute, ...gameData} }: { result: GameDataRedirect }) {

		const navigate = useNavigate();

		React.useEffect(() => {
			if (redirectRoute != null) {
				navigate(redirectRoute);
			}
		}, []);

		if (gameData.game.roundIndex < 0) {
			return <GameSetup {...gameData} onStartGame={() => props.session.startGame()}/>
		} else if (gameData.game.roundIndex < gameData.game.gameRules.numberOfRounds) {
			return <GameRound {...gameData} onNextRound={() => props.session.nextRound()} onAbort={() => {
				props.session.gameModel.restartGame();
			}} />
		}
		return <GameEnd {...gameData} onRestart={() => {
			props.session.gameModel.restartGame(); // props.session.gameModel.restartGame();
		}}/>
	}
	function ErrorComponent(props: {error: any}) {
		if (props.error.message === "You need to be logged in to be in a game."){
			const navigate = useNavigate();

			React.useEffect(() => {
				navigate("/account");
			}, []);
		}
		return <Error code={500} title="Internal Server Error" body={<p>{(props.error.message || props.error).toString()}</p>} />
	}

	return <PromiseRenderer
	promiseHandler={gameRoundData}
	resultComponent={observer(ResultComponent)}
	errorComponent={ErrorComponent}
	/>
});
