import * as React from "react";
import { observer } from "mobx-react-lite";
import { AuthView } from "src/views/authView";
import { User as UserModel } from "src/model/userModel";
import { PromiseRenderer } from "src/components/promiseRenderer";
import { SessionProps } from "src/model/model";
import { AccountView } from "src/views/accountView";


function Auth({session}: SessionProps) {
    return <PromiseRenderer promiseHandler={session.userModel.userHandler} resultComponent={observer(AccountComponent)} notLoadingComponent={observer(noAccountComponent)}/>;

    /** Component run when the user is signed out. */
    function noAccountComponent() {
        return (
            <>
                <AuthView 
                    submitForm={submitFormACB}
                    toggleSignUp={toggleSignUpACB}
                    errorOccurred={session.auth.error}
                    signUp={session.auth.signUp}
                    errorMessage={session.auth.errorMessage}
                />
            </>
        );

        /** Asynchronous callback for submitting the log-in or registration form. */
        async function submitFormACB(event: Event) {
            event.preventDefault();
            if (session.auth.signUp) {
                session.userModel.createAccount(event.target[0].value, event.target[1].value, event.target[2].value)
                .catch((error: Error) => {session.auth.displayError(error);});
            } else {
                await session.userModel.authenticateAccount(event.target[0].value, event.target[1].value)
                .catch((error: Error) => {session.auth.displayError(error);});
            }
        }

        /** Asynchronous callback for toggling form mode (login/registration) */
        function toggleSignUpACB() {
            session.auth.toggleSignUp();
        }
    }

    /** Component run when the user is logged in. */
    function AccountComponent({result: user}: {result: UserModel}) {
        return (<>
            <AccountView
                signOut={signOutACB}
                user={user.firebaseUser}
            />
        </>);

        /** Asynchronous callback for signing out. */
        function signOutACB() {
            session.userModel.signOutOfAccount()
            .catch((error: Error) => {session.auth.displayError(error);});
        }
    }
};

export { Auth }
