import * as React from "react";
import {GameData} from "src/model/model";
import {GameSetupView} from "src/views/game/gameSetupView";
import {Round} from "src/model/roundModel";
import {observer} from "mobx-react-lite";

// Importing Enum definition
import { RoundLength } from "src/model/gameModel";

export interface GameSetupProps extends GameData {
	onStartGame: () => void;
}
export const GameSetup = observer(({game, user, onStartGame}: GameSetupProps) => {
	return <GameSetupView
		inviteURL={window.location.href}
		isHost={game.currentUserId == game.hostUserId}
		users={game.userIds.map((userId) => {
			return {
				id: userId,
				username: game.userNames[userId],
				host: userId == game.hostUserId,
				you: userId == game.currentUserId
			}
		})}
		defaultRoundLength={game.gameRules.roundLength}
		defaultRoundNumber={game.gameRules.numberOfRounds}
		setRoundNumber={(roundNumber) => game.gameRules.setNumOfRounds(roundNumber)}
		setRoundLength={(roundLength) => game.gameRules.setRoundLength(roundLength)}
		formSubmit={(event = null) => {
			event != null && event.preventDefault();
			onStartGame()
		}}
	/>;
})
