import * as React from "react";

export function AccountView(props: any) {
    return (
        <main id="auth" className="auth-container animation-wrapper-load">
            <div className="box">
                <p>Currently signed in as <b>{props.user.displayName}</b>.</p><br/> {/* Problem: Username will not be shown when an account has just been created. */}
                <input type="button" onClick={() => props.signOut()} value="Sign out"></input>
            </div>
        </main>
        );
}