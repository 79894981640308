import { initializeApp } from "firebase/app";
import { getDatabase, ref, get, set, update, query, onValue, orderByChild, limitToFirst, equalTo, push, onChildChanged } from "firebase/database";
import { getAuth } from "firebase/auth";
import { firebaseConfig } from "src/modules/firebaseConfig";
import { reaction } from "mobx";

// Type imports for TypeScript
import { Game } from "src/model/gameModel";
import { Persistable } from "src/model/persistable";
import { Session } from "src/model/model";
import { PromiseHandler } from "src/model/promiseHandler";
import {LeaderboardData } from "src/model/leaderboardModel";

// Initialisation
const app = initializeApp(firebaseConfig);
const db = getDatabase(app);
const auth = getAuth(app);

const userPath = "users/";
const gamePath = "games/";

/* ---------- */
// Functions
/* ---------- */

/* --------------------------------- */
// General-purpose Firebase Functions
/* --------------------------------- */

/** Saves to model data to the Firebase database. (merges using update) */
async function saveToFirebase(model: Persistable, savePath: string) {
    //console.log("Saving to path:", savePath);
    if (model.dbReady) {
        await update(ref(db, savePath), model.modelToDb());
    }
}

/** Sends the Firebase database data from the given path to the given model. */
async function readFromFirebase(model: Persistable, readPath: string) {
    model.setReady(false);
    //console.log("Reading from path:", readPath);
    const snapshot = await get(ref(db, readPath));
    const doUpdate = model.dbToModel(snapshot.val());
    model.setReady(true);
	if (doUpdate != null && doUpdate) {
		await saveToFirebase(model, readPath);
	}
}

/** Connects a model to Firebase using a watch function, which monitors the specified important properties of the model. */
async function connectModelToFirebase(model: Persistable, path: string, watchFunction: Function, watchedProperties: string[]) {
    await readFromFirebase(model, path);
    watchFunction(
        () => watchedProperties.map((value) => {
            return value.split(".").reduce((acc, key) => { return acc[key] }, model);
        }),
        () => saveToFirebase(model, path) // Side effect, saving to Firebase
    );
}

/** Listens to changes in a Firebase database path, and then reads that data to the specified model. */
function listenToFirebaseModel(model: Persistable, path: string) {
    onValue(ref(db, path), () => {
        readFromFirebase(model, path);
    })
}

/* ---------------------------- */
// Specific Database Functions
/* ---------------------------- */

/** Connects newly created games to the database for persistence. */
async function connectGameToDb(game: PromiseHandler<Game>) {
    await connectGameToFirebase(game, reaction);
	listenToFirebaseModel(game.result, gamePath + game.result.id)
}

export async function getHostedGameFromUid(userId: string) {
    return get(query(ref(db, gamePath), orderByChild("gameHostUid"), equalTo(userId)));
}

/** Connects a game to Firebase. Because of the complex structure of games, this is its own function. */
async function connectGameToFirebase(game: PromiseHandler<Game>, watchFunction: Function) {
    await readFromFirebase(game.result, gamePath + game.result.id);
	game.result.finishLoad();

    watchFunction(
        () => {
			if (game.result == null) { // If there currently is no game, only watch game.result.
				return [game.result];
			}
			return [ // Important changes
				game.result.gameRules.numberOfRounds, 
			    game.result.gameRules.roundLength,
				game.result.rounds.length,
                game.result.roundIndex,
                game.result.userIds
			]
		},
        () => {
			if (game.result != null) { // Only saves if game actually exists.
				saveToFirebase(game.result, gamePath + game.result.id) // Side effect, saving to Firebase
                // TODO: Find a more elegant solution that does not involve creating a watch function for every round.
                if (game.result.roundIndex != null) {
                    watchFunction(() => {
						if (game.result == null) {
							return [game.result];
						}
						return game.result.rounds.map((round) => [
							round.guesses.length,
							round.startTime, 
							round.hasEnded,
							round.correctPosition,
						]).flat();
					}, 
                    () => {
						if (game.result == null) {
							return;
						}
                        saveToFirebase(game.result, gamePath + game.result.id)
                    });
                }
            }
		}
    );
}

/** Connects the entire model to Firebase. */
function connectToFirebase(model: Session, watchFunction: Function) {
    const user = model.userModel.userHandler;
    const game = model.gameModel.handler;
    readLeaderboardFromFirebase(model);
    
    /* 
    Since large parts of our model does not exist until certain actions have been taken by the user,
    we use a watch function to ensure that the appropriate connection is made when a new part of the model is created.
    This way, we avoid mixing application state and persistence.
    */
    
    watchFunction(() => [user.result, game.result], async () => {
        if (user.result) {
            await connectModelToFirebase(user.result, userPath + user.result.id, reaction, ["highScore", "firebaseUser"]);
            listenToFirebaseModel(user.result, userPath + user.result.id);
        }

        if (game.result) {
            connectGameToDb(game);
        }
    });
}

/** Gets the name of a user (as a promise) from their UID. */
async function getUsernameFromUid(uid: string) {
    return get(ref(db, userPath + uid)).then((snapshot) => snapshot.val());
}

/** Gets the Leaderboard from Firebase and restructures it to fit in the model. */
async function getLeaderboard() : Promise<LeaderboardData> {
    /* Since the query returns the data as an unordered JS object, we have to re-sort it client-side.
    Another possible implementation could be to use snapshot.forEach((child) => ...) to receive them in a sorted order.*/ 
    return get(query(ref(db, userPath), orderByChild("userData"), limitToFirst(20)))
    .then((snapshot) => snapshot.val())
    .then((data) => [... Object.keys(data)]
    .map((key) => {
        return {username: data[key].name, score: data[key].userData, userId: key};
    }).sort((userA, userB) => {
        return userB.score - userA.score;
    }));
};

async function readLeaderboardFromFirebase(model: Session) {
    await model.leaderboard.setLeaderboard(getLeaderboard());
    model.leaderboard.finishLoad();
    onValue(ref(db, userPath), async () => {    
        await model.leaderboard.setLeaderboard(getLeaderboard())
        model.leaderboardHandler.handlePromise(model.getLeaderboardData());
    });
}

export { auth, connectToFirebase };