import iso from 'iso-3166-1';
const yt_API = "https://www.googleapis.com/youtube/v3/";
const KEY = process.env.YOUTUBE_API_KEY || "AIzaSyBz65IBSC3LoLU_iuZnXYqQpFImP22OnRM";
import L from "leaflet"

const options = {
	method: 'GET',
	headers: {
		"X-Goog-Api-Key": KEY
	}
};

export interface Video{
	videoId: string,
	channelId: string,
	recordingDetails?: any
}
export interface VideoCountry extends Video{
	country: string
}

export function fetchSingleVideo() {
    return fetch(
       yt_API
        + "search?" + new URLSearchParams({
			access_token:KEY,
			q: "rickroll"
		}), options)
        .then((response) => response.json()).then((data) => {console.log(data)});
}

/**
 * Get the country of a YouTube channel from its channel ID.
 * @param channelId - Channel ID of the YouTube channel to retrieve the country from.
 * @returns Resolves to a string with the name of the country that the channel is from, if that information is available.
 */
export async function getCountryFromChannelId(channelId: string): Promise<string> {
    const response = await fetch(
       yt_API
        + "channels?" + new URLSearchParams({
			access_token: KEY,
			id: channelId,
			part: "snippet"
		}), options);
	if (!response.ok) {
		throw new Error(`Error: ${response.status}, ${response.statusText}`);
	}

    const data = await response.json();
	console.log(data);

	const country = data.items[0]?.snippet?.country;
	if (!country) {
        throw new Error("Country information is unavailable for the provided channel ID.");
    }

    return iso.whereAlpha2(country)?.country || "Unknown Country";
}

/**
 * Retrieve a number of the "most popular" videos and get the information from a randomly selected video among them.
 * @param maxResults - The number of "most popular" videos to retrieve. Defaults to 20.
 */
export async function getRandomVideo(maxResults: number = 20): Promise<Video> {
	const response = await fetch(
		yt_API
        + "videos?" + new URLSearchParams({
			access_token: KEY,
			part: "snippet",
			chart: "mostPopular",
			maxResults: maxResults.toString(),
		}), options)
	if (!response.ok){
		throw new Error(`Error: ${response.status}, ${response.statusText}`);
	}
	const data = await response.json();
	// console.log(data);
	const videos = data.items;
	const randomVideo = videos[Math.floor(Math.random()*videos.length)]

	if (!randomVideo || !randomVideo.id || !randomVideo.snippet?.channelId) {
		throw new Error("Random video does not have a valid ID or channel ID.");
	}

	return {videoId: randomVideo.id,
			channelId: randomVideo.snippet.channelId
	}

}

export async function addCountryToVideo(video: Video): Promise<VideoCountry> {
	const country = await getCountryFromChannelId(video.channelId)
	return {
		videoId: video.videoId,
		channelId: video.channelId,
		country: country
	}
}

export interface YoutubeVideoResult {
	etag: string,
	kind: string,
	id: {
		kind: string,
		videoId: string
	},
	snippet: {
		channelId: string,
		channelTitle: string,
		description: string,
		liveBroadcastContent: string,
		publishTime: string,
		publishedAt: string,
		thumbnails: any,
		title: string,
	}
}

type YoutubeSearchResults = {
	items: YoutubeVideoResult[],
	etag: string,
	kind: string,
	nextPageToken: string,
	pageInfo: {
		resultsPerPage: number,
		totalResults: number
	},
	regionCode: string
}

export function getRandomVideoFromResults(searchResults: YoutubeSearchResults): YoutubeVideoResult{
	return searchResults.items[Math.floor(Math.random() * searchResults.items.length)]
}

/**
 * Retrieves YouTube videos around a coordinate point within a range and returns a random one.
 * @param coordinate - The coordinate point to search around.
 * @param maxDistance - The upper limit of the search range in kilometers. Should not be greater than 1000. Defaults to 10.
 * @param maxResults - The number of search results to return (if enough matching videos are found). Should not exceed 50. Defaults to 50.
 * @returns Resolves to a {@link Video}.
 */
export async function getVideoFromCoordinates(coordinate: L.LatLng, maxDistance: number = 100, maxResults: number = 50): Promise<Video>{
	const searchResults = await fetch(
		yt_API
        + "search?" + new URLSearchParams({
			access_token: KEY,
			type: "video",
			part: "snippet",
			location: `${coordinate.lat}, ${coordinate.lng}`,
			locationRadius: `${Math.min(maxDistance, 1000)}km`,
			videoEmbeddable: "true", // only return videos that can actually be embedded onto the site
			videoSyndicated: "true", // only return videos that can be view on platforms other than YouTube
			maxResults: (Math.min(maxResults, 50)).toString(),
		}), options)
		.then(response => response.json())

	// If an error is received from API, throw it!
	if (searchResults.error != null) {
		throw searchResults.error;
	}

	const randomVideo = getRandomVideoFromResults(searchResults);
	const recordingDetails = (await getVideoDetails(randomVideo.id.videoId)).items[0].recordingDetails
	//console.log(new L.LatLng(recordingDetails.location.latitude, recordingDetails.location.longitude));
	return {
		channelId: randomVideo.snippet.channelId,
		videoId: randomVideo.id.videoId,
		recordingDetails: recordingDetails
	};
}

/**
 * Retrieves the details (notably recordingLocation) from a video with a given id.
 * @param id - Id of the video to retrieve details from.
 */
export async function getVideoDetails(id: string): Promise<any>{
	const videoDetails = await fetch(
		yt_API
        + "videos?" + new URLSearchParams({
			access_token: KEY,
			part: "recordingDetails",
			id: id
		}), options)
		.then(response => response.json())


	if (videoDetails.error != null) {
		throw videoDetails.error;
	}
	return videoDetails;
}
