/// <reference types="vite-plugin-svgr/client" />
import Logo from "src/svg/logo.svg?react"; // Imports the SVG as a component: https://stackoverflow.com/a/70314031/13347795
import ConsoleControllerSVG from "src/svg/console-controller.svg?react";
import LeaderboardSVG from "src/svg/leaderboard-icon.svg?react";

import * as React from "react";

import {User} from "src/model/userModel";
import {PromiseHandler} from "src/model/promiseHandler"
import {PromiseRenderer} from "src/components/promiseRenderer";

import {Link} from "react-router-dom";
import {Profile} from "src/components/profile";

import { observer } from "mobx-react-lite";

import "src/styles/navbar.css";
import "src/styles/dropdown.css";

interface NavbarProps {
	userHandler: PromiseHandler<User>;
	/** Called when either the "Sign up" or "Log in" button is clicked.
	 * Clicking either button will also automatically redirect to the "/account" page.
	 * @param signUp True if the "Sign up" button is clicked and not the "Log in" button.
	 */
	onAccountClick: (signUp: boolean) => void;
}
export function NavbarView(props: NavbarProps) {
	function LoginRenderer() {
		return (
			<div className="nav-auth-wrapper">
				<div className="panel login-container">
					<Link to="account" onClick={() => props.onAccountClick(true)} className="hide-on-mobile">Sign up</Link>
					<Link to="account" onClick={() => props.onAccountClick(false)}>Log in</Link>
				</div>
			</div>
		)
	}

	function accountRenderer({result}: {result: User}) {
		return (
			<Link to="account" className="button-hover login-container">
				<Profile username={result.name} id={result.id || "placeholder"} removeUsernameOnMobile/>
			</Link>
		);
	}

	return <div className="navbar shadow-minor">
		<Link to="/" className="logo-button button-hover">
			<div className="logo-container">
				<Logo className="logo-svg" />
				<span className="hide-on-mobile">YouGuessr</span>
			</div>
		</Link>
		<div>
			<Link className="navbar-link" to="/game">
				<div className="navbar-icon hide-on-small-mobile">
					<ConsoleControllerSVG className="console-controller-svg" />
					{/*https://game-icons.net/1x1/skoll/console-controller.html*/}
				</div>
			&nbsp;Play
			</Link>
		</div>
			<Link className="navbar-link" to="/leaderboard">
				<div className="navbar-icon hide-on-small-mobile">
					<LeaderboardSVG className="console-controller-svg"/>
					{/* Made it myself :sunglasses: */}
				</div>
			&nbsp;Leaderboard
			</Link>
		<PromiseRenderer promiseHandler={props.userHandler} resultComponent={observer(accountRenderer)} notLoadingComponent={LoginRenderer}/>
	</div>;
}
