// Type imports for TypeScript
import { PromiseHandler } from "./promiseHandler";
import { User } from "./userModel";

/** Represents the array of all leaderboard entries to be rendered. */
export type LeaderboardData = {
    userId: string, 
    score: number, 
    username: string[]
}[];

export class Leaderboard {
    /** A leaderboard to read score data from. */
    leaderboard: LeaderboardData;

    /** Boolean describing if the leaderboard data is accessible or not. */
    hasLoaded: boolean = false;

    /** The promise of a user for reading UID from. */
    user: PromiseHandler<User>;

    /** Returns the leaderboard when it is defined. */
    async getLeaderboard() {
		if (!this.hasLoaded) {
			await new Promise<void>((resolve) => {
				this.initialLoadACB = () => resolve();
			});
		}
		return this.leaderboard;
    }

    /** Sets the leaderboard. */
    async setLeaderboard(newLeaderboard: Promise<LeaderboardData>) {
        this.leaderboard = await newLeaderboard; 
    }

    /** Finishes the loading, meaning the leaderboard can be returned. */
    finishLoad() {
		this.initialLoadACB?.();
		this.hasLoaded = true;
	}

    initialLoadACB?: () => void;
}