import * as React from "react";

interface SpaceListenerProperties {
	onSpacePressed: () => void;
}

export function SpaceListener(props: SpaceListenerProperties){
	React.useEffect(() => {
		function onKeyDown(e){
			// keyCode 32 is space
			if (e.keyCode == 32) {
				props.onSpacePressed();
			}
		}
		window.addEventListener("keydown", onKeyDown)
		return () => {
			window.removeEventListener("keydown", onKeyDown) // remove the event listener when switching to a different page
		}
	}, [])
	return null;
}