import * as React from "react";
import {Routes, Route, useLocation} from "react-router-dom";

// Component imports
import {Navbar} from "src/presenters/navbarPresenter";

// Page imports
import {Home} from "src/views/homeView";
import {GamePresenter} from "src/presenters/gamePresenter";
import {Leaderboard} from "src/presenters/leaderboardPresenter";
import {Auth} from "src/presenters/authPresenter";
import {Error} from "src/views/errorView";

export function Root(props) {
	function Error404Body() {
		return <>
			<p>The page you requested at <code>{useLocation().pathname}</code> could not be found.</p>
			<p>Terribly sorry!</p>
			<p>No games, but unlimited bacon.</p>
			<img src="/assets/scaredhamster.png" alt="Blurry photo of a hamster with a scared expression." />
			<p>
				<i>Best regards,</i><br />
				<b>The YouGuessr&reg; Team</b>
			</p>
		</>
	}

	return <>
		<Navbar session={props.session} />
		<Routes>
			<Route path="/" element={<Home />} />
			<Route path="/game/:id?" element={<GamePresenter session={props.session} />} />
			<Route path="/leaderboard" element={<Leaderboard session={props.session} />} />
			<Route path="/account" element={<Auth session={props.session}/>}/>
			<Route path="*" element={
				<Error code={404} title={"Not Found"} body={<Error404Body/>}/>
			}/>
		</Routes>
		<footer className="footer-earth"/>
	</>;
}
