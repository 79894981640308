import * as React from "react";
import L from "leaflet";
import { MapView } from "src/views/mapView";
import { observer } from "mobx-react-lite";
import { GeoJSON } from "react-leaflet";
import { countryToNameAndFlag, formatDistance, getVisualMarkerPosition, getCountryFromCoordinates } from "src/modules/mapApi";
import { Round } from "src/model/roundModel";

export interface MapProps {
    round: Round;
    finishZoomAnimation?: () => void;
    isInResults: boolean;
}

export const Map = observer(
    // todo: make isInResults default to false
    function Map(props: MapProps){

        const [playClickSound, setPlayClickSound] = React.useState<boolean>(false);

        function setMarkerACB(latlng: {lat: number, lng: number}){
            if (props.isInResults) return;
            props.round.setUserPosition(L.latLng(latlng.lat, latlng.lng));
            setPlayClickSound(true);
        }

        function finishZoomAnimationACB(){
            props.finishZoomAnimation?.()
        }

        function onSoundEndClickACB(){
            setPlayClickSound(false);
        }

        function GeoJSONData(attr: {color: string}){
            return props.round.userPosition?.country?.geometry && <GeoJSON data={props.round.userPosition.country.geometry} style={function(){return {color: attr.color}}}/>
        }

        function CorrectCountryGeoJSONData(){
            return <GeoJSON data={props.round.correctPosition.country?.geometry} style={function(){return {color: "var(--color-success)"}}}/>
        }


        return (

            <MapView

            isInResults={props.isInResults}

            onMapClick={setMarkerACB}
            markerPosition={props.round.userPosition && getVisualMarkerPosition(props.round.userPosition.position, props.round.userPosition.region)}
            countryName={props.round.userPosition?.country && countryToNameAndFlag(props.round.userPosition?.country)}
            geoJson={<GeoJSONData 
                color={
                    props.isInResults ?
                    (props.round.userPosition?.country?.name !== props.round.correctPosition.country?.name ? 
                    "red" : "var(--color-success)") :
                    "blue"
                }/>}
            correctPosition={props.round.correctPosition.position}
            correctCountryGeoJson={
                props.isInResults &&
                props.round.userPosition?.country?.name !== props.round.correctPosition.country?.name &&
                <CorrectCountryGeoJSONData/>
            }
            correctCountryName={countryToNameAndFlag(props.round.correctPosition.country)}

            // TODO: Make sure it can handle users not having guessed
            playerGuesses={
                props.isInResults && 
                props.round.guesses &&
                (Object.keys(props.round.guesses).map(userId => {
                    return {
                        position: props.round.guesses[userId].position,
                        countryName: countryToNameAndFlag(getCountryFromCoordinates(props.round.guesses[userId].position))
                    }
                }))
            }
            
            playClickSound={playClickSound}

            onSoundEndClick={onSoundEndClickACB}
            onFinishZoomAnimation={finishZoomAnimationACB}
            />
        )
    }
);
