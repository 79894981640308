import * as React from "react";
import {GameRoundStatusView} from "src/views/game/round/gameRoundStatusView";
import {GameRoundVideoView} from "src/views/game/round/gameRoundVideoView";
import {GameRoundSubmitView} from "src/views/game/round/gameRoundSubmitView";
import {GameRoundResultsView} from "src/views/game/round/gameRoundResultsView";
import {GameData} from "src/model/model";
import {countryToNameAndFlag, formatDistance} from "src/modules/mapApi";
import {Map} from "src/presenters/mapPresenter";
import {YouTubeEvent} from 'youtube-player/dist/types';
import {observer} from "mobx-react-lite";

// CSS imports
import "src/styles/game.css";
import "src/styles/style.css"

export interface GameRoundProps extends GameData {
	onNextRound: () => void;
	onAbort: () => void;
}
export const GameRound = observer(({user, game, onNextRound, onAbort}: GameRoundProps) => {
	const round = game.rounds[game.roundIndex];

	// Code for pausing video when submitting guess. Should maybe not be in presenter? 
	// Not sure where in the MVP architecture it belongs but it feels like the model should not have anything to do with whether the video is playing or not
	// I'm thinking: Component state. -David
	const [youtubePlayer, setYoutubePlayer] = React.useState(null);
	function onVideoReadyACB(event: YouTubeEvent){
		setYoutubePlayer(event.target); // stores a reference to the video player
	}

	function onSubmitACB() {
		if (round.userPosition != null && !round.hasEnded) {
			game.submitGuess();
			if (youtubePlayer != null) {
				youtubePlayer.pauseVideo()
			}
		}
	}

	/*
	This presenter does contain a lot of HTML elements, but they are not visible themselves and only intended for layout purposes.
	We can't move them to a view, because the <Map /> presenter is inside of them and needs direct access to the model.
	While we could create more layers of presenters for game-container and game-map-video-wrapper to make this presenter more
	compliant, but we decided against it because it would increase the complexity of the structure and make it harder to understand.
	*/

	return <main className="game-main">
		<GameRoundStatusView
			score={game.getTotalScore(game.currentUserId)}
			isHost={game.currentUserId == game.hostUserId}
			currentRound={game.roundIndex+1}
			totalRounds={game.gameRules.numberOfRounds}
			endTime={game.roundEndTime}
			paused={round.hasEnded}
			onAbort={() => onAbort()}
		/>
		<div className="game-container">
			<div className="game-map-video-wrapper">
				<GameRoundVideoView videoId={round.video.videoId} onVideoReady={onVideoReadyACB}/>
				<Map round={round} isInResults={round.hasEnded}/>
			</div>
			{!round.hasEnded &&
				<GameRoundSubmitView onSubmit={onSubmitACB} disabled={round.userPosition == null} />
			}
		</div>
		{round.hasEnded &&
		<GameRoundResultsView
			didUserGuess={!!round.userPosition}
			isCountryCorrect={round.userPosition?.country?.name == round.correctPosition.country?.name}
			correctCountryText={countryToNameAndFlag(round.correctPosition.country)}
			userCountryText={countryToNameAndFlag(round.userPosition?.country)}
			distanceText={
				round.guesses?.[game.currentUserId]?.distance &&
				formatDistance(round.guesses[game.currentUserId].distance)
			}
			score={round.guesses?.[game.currentUserId] && round.guesses[game.currentUserId].score}
			isFinalRound={game.rounds.length === game.roundIndex + 1}
			onNextRound={() => onNextRound()}
		/>
		}
	</main>
});
