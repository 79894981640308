import React, { useState, useEffect } from "react";

export interface TimerProps {
	/** null indicates an infinite game time. */
	endTime?: Date;
}
export function Timer({endTime = null}: TimerProps) {
	const [time, setTime] = useState<number | null>(null);
	

	useEffect(() => {
		function setRemainingTime() {
			if (endTime == null) {
				setTime(null);
			} else {
				/** Remaining time in milliseconds. */
				const remainingTime = endTime.getTime() - new Date().getTime();
				if (remainingTime >= 0) {
					setTime(Math.ceil(remainingTime/1000)); // Rounds to seconds.
					setTimeout(setRemainingTime, remainingTime%1000);
				}
			}
		}
		setRemainingTime();
	}, []);

	return (
		<div className="timer">
			<span>Time:</span>
			<span>{time == null ? "∞" : time}</span>
		</div>
	)

}
