import L from "leaflet";
import { Polygon, BBox, MultiPolygon, Feature } from "geojson";
import booleanPointInPolygon from "@turf/boolean-point-in-polygon";
import makePolygon from "turf-polygon";
import { countryJson } from "src/data/countries";

export type Country = {
	name: string,
	flag: string,
	geometry?: Polygon | MultiPolygon,
	bboxes: BBox | BBox[],
	selectedBBox?: BBox
}

export interface CountryAndPosition {
    position: L.LatLng;
    region: number;
    country?: Country | null;
}

/**
 * Retrieves a {@link Country} based on a coordinate point within that country.
 * @param coordinates - An object with a latitude and a longitude.
 */
export function getCountryFromCoordinates(coordinates: L.LatLng): Country{
    const countryData = countryJson.features.find(country => {
        return booleanPointInPolygon([coordinates.lng, coordinates.lat], country);
    })
    return countryData && formatCountryData(countryData);
}

/**
 * Retrieves a {@link Country} based on a string representing its name.
 * @param name - The country's name.
 */
export function getCountryFromName(name: string): Country{
    const countryData = countryJson.features.find(country => {
        return country.properties["ADMIN"] === name;
    })
    return countryData && formatCountryData(countryData);
}

/**
 * Extracts and formats country data in the form of {@link countryJson}
 * @param countryData - One row of data in {@link countryJson}, representing one country
 */
function formatCountryData(countryData: Feature<MultiPolygon> | Feature<Polygon>): Country{
    let country = countryData;
	if (country == null) {
		return null;
	}
    return {
        name: country.properties["ADMIN"],
        flag: getFlagEmoji(country.properties["ISO_A2"]),
        bboxes: country.geometry.type === "MultiPolygon" ? multiPolygonToBboxes(country.geometry) : polygonToBBox(country.geometry.coordinates),
        geometry: country.geometry
    }
}

/**
 * Creates an array containing a {@link BBox} for each Polygon within a {@link MultiPolygon}.
 */
function multiPolygonToBboxes(multipolygon: MultiPolygon): BBox[]{
    return multipolygon.coordinates.map(polygon => {
        return polygonToBBox(polygon);
    })
}

/**
 * Unused but saved in case we want a different solution than the current one.
 * Makes countries that cross the antimeridian to the east render to the east of it rather than wrap around to the west. 
 */
function fixPolygonWrapping(multipolygon: Feature<MultiPolygon>, threshold: number = -165): Feature<MultiPolygon>{
    return {...multipolygon, geometry: {
        ...multipolygon.geometry, coordinates: multipolygon.geometry.coordinates[0].map(polygon => {
            return [polygon.map(coordinate => {
                return coordinate[0] > threshold ? coordinate : [coordinate[0] + 360, coordinate[1]];
            })]
        })

}}}

/**
 * Get a flag emoji from a country code.
 * @param countryCode - A string representing the ISO3166-1 country code of a country.
 * @author https://dev.to/jorik/country-code-to-flag-emoji-a21#comment-1d92e
 * @returns A string containing the emoji flag of the country. May be null.
 */
function getFlagEmoji(countryCode): string | null {
    return countryCode && countryCode.toUpperCase().replace(/./g, char => 
        String.fromCodePoint(127397 + char.charCodeAt())
    )
}

/**
 * Generate a random LatLng within the bounds of a Polygon using brute force.
 * @param polygonCoordinates - Coordinates for the Polygon in which to generate a random position.
 * @param [maxTries=50] - The maximum number of tries before the function aborts the process.
 * @returns A LatLng (if one was found)
 * @throws Error if no position was found in maxTries tries.
 */
export function getRandomPositionInPolygon(polygonCoordinates: number[][][], maxTries: number = 50): L.LatLng{
    const bbox = polygonToBBox(polygonCoordinates);
    let tries: number = 0;
    let pointFound: boolean = false;
    let point: L.LatLng = null;
    while (!pointFound && tries < maxTries){
        point = getRandomPointInBBox(bbox);
        if (isPointInPolygon(point, makePolygon(polygonCoordinates))) pointFound = true;
        tries++;
    }

    if (!pointFound){
        throw new Error(`Failed to generate random position within country polygon in ${maxTries} tries.`)
    }

    return point;
}

/**
 * Generate a random LatLng within a BBox
 */
function getRandomPointInBBox(bbox: BBox): L.LatLng {
    return new L.LatLng(
        Math.random() * (bbox[3] - bbox[1]) + bbox[1],
        Math.random() * (bbox[2] - bbox[0]) + bbox[0]
    )
}

/**
 * Return true if a LatLng is contained within a Polygon.
 */
function isPointInPolygon(point: L.LatLng, polygon: Polygon): boolean {
    return booleanPointInPolygon([point.lng, point.lat], polygon)
}

/**
 * Calculate the BBox for a Polygon within a MultiPolygon.
 * @param polygonCoordinates The coordinates of a Polygon within a MultiPolygon.
 */
function polygonToBBox(polygonCoordinates: number[][][]): BBox {
    // Flatten the array of coordinates to get all points in the Polygon
    const flattenedCoordinates = polygonCoordinates.flat(1);
    // @ts-expect-error
    return flattenedCoordinates.reduce(
        (accumulator, coordinate) => [
            Math.min(accumulator[0], coordinate[0]), // Lowest longitude
            Math.min(accumulator[1], coordinate[1]), // Lowest latitude
            Math.max(accumulator[2], coordinate[0]), // Highest longitude
            Math.max(accumulator[3], coordinate[1]), // Highest latitude
        ],
        [Infinity, Infinity, -Infinity, -Infinity]
    );
}

/**
 * Compute the area of a bounding box. 
 * @returns The area of a BBox in meters squared.
 * @see https://leafletjs.com/reference.html#latlng-distanceto
 */
function computeBBoxArea(bbox: BBox): number{
    // not sure if this is really a totally accurate way of doing this but it's good enough for our use case
    return (
        (new L.LatLng(bbox[1],bbox[0])).distanceTo(new L.LatLng(bbox[1],bbox[2])) *
        (new L.LatLng(bbox[1],bbox[0])).distanceTo(new L.LatLng(bbox[3],bbox[0]))
    )
}

/**
 * Create an array of each BBox's percentage of the total area of all BBoxes in bboxes
 * @param bboxes - Array of {@link BBox}es
 */
function calculateBBoxWeights(bboxes: BBox[]): number[]{
    const areas = bboxes.map(computeBBoxArea);
    const totalArea = areas.reduce((sum, area) => sum + area, 0);
    return areas.map(area => area/totalArea);
}

/**
 * Check whether a variable is one bbox or an array of bboxes
 * @param bboxes - Either a BBox or an array of BBoxes. 
 * @returns False if bboxes is not an array of bboxes, otherwise true.
 */
export function checkIsBBoxArray(bboxes: BBox | BBox[]): boolean {
    return !(typeof bboxes[0] === "number")
}

/**
 * Chooses a random BBox from an array based on the BBoxes' relative areas
 * @author Based on https://stackoverflow.com/a/57130749
 */
export function getRandomWeightedBBoxIndex(bboxes: BBox | BBox[]): number {
    if (!checkIsBBoxArray(bboxes)) return 0;
    const weights = calculateBBoxWeights(bboxes as BBox[]);
    var cdf = weights.map((sum => value => sum += value)(0));
    var rand = Math.random();
    return cdf.findIndex(element => element >= rand)
}

/**
 * @returns A randomly selected country name from countries.ts.
 */
export function getRandomCountryName(): string{
    return countryJson.features[Math.round(Math.random() * countryJson.features.length)].properties["ADMIN"];
}

/**
 * @returns A randomly selected country from countries.ts.
 */
export function getRandomCountry(): Country{
    return formatCountryData(countryJson.features[Math.round(Math.random() * countryJson.features.length)])
}

/**
 * Chooses a random country and retrieves a random position from within that country.
 * @returns An object containing a {@link Country}, a LatLng position and a {@link BBox}.
 */
export function getRandomCountryAndPosition(): CountryAndPosition {
    const randomCountry = getRandomCountry();
    const weightedIndex = getRandomWeightedBBoxIndex(randomCountry.bboxes);
    const randomWeightedPolygon = checkIsBBoxArray(randomCountry.bboxes) ? (randomCountry.geometry as MultiPolygon).coordinates[weightedIndex] : (randomCountry.geometry as Polygon).coordinates;
    return {
        country: randomCountry,
        region: 0,
        position: getRandomPositionInPolygon(randomWeightedPolygon)
    }
}

/**
 * Formats a country as "[flag] [name]"
 * @param country - {@link Country} to format.
 * @example 🇴🇲 Oman
 */
export function countryToNameAndFlag(country?: Country): string {
	if (country?.name == null || country?.flag == null) {
		return "outside of a country";
	}
    return `${country.flag} ${country.name}`
}

/**
 * Check if two bboxes are equal by comparing the coordinates of the two corners.
 * @returns True if both corners are equal, otherwise false.
 */
export function bboxesEqual(bbox1: BBox, bbox2: BBox): boolean {
    let isEqual = true;
    for (let i = 0; i < bbox1.length; i++){
        isEqual = bbox1[i] === bbox2[i]
        if (!isEqual) return false; 
    }
    return isEqual
}

export function filterBBoxes(bboxes: BBox[], bbox: BBox): BBox[]{
    return bboxes.filter(currentBBox => !bboxesEqual(bbox, currentBBox))
}

/**
 * Format a distance
 * @param distance - Distance in meters
 */
export function formatDistance(distance: number): string{
    if (distance > 1000) return `${(distance/1000).toFixed(1)}km`
    return `${Math.round(distance)}m`
}

/**
 * Checks whether a LatLng is extraregional (i.e. has a lng value greater than 180 or less than -180).
 * @param coordinate The LatLng to check for extraregionality
 * @param epsilon The threshold above which to consider the LatLng to be extraregional
 * @returns 0 if not extraregional, 1 if extraregional with a higher longitude, -1 if extraregional with a lower longitude 
 */
export function checkIsLatLngExtraregional(coordinate: L.LatLng, epsilon: number = 100): number{
    const wrappedCoordinate = coordinate.wrap();
    if (Math.abs(coordinate.lng - wrappedCoordinate.lng) > epsilon) return Math.sign(coordinate.lng - wrappedCoordinate.lng);
    return 0;
}

/**
 * Adds or subtracts 360 degrees from a LatLng in order to make it extraregional
 * @param coordinate 
 * @param markerRegion 
 * @returns 
 */
export function getVisualMarkerPosition(coordinate: L.LatLng, markerRegion: number): L.LatLng{
    return sumLatLng(coordinate, L.latLng(0, markerRegion * 360))
}

/**
 * Add two LatLngs together
 */
export function sumLatLng(latlng1: L.LatLng, latlng2: L.LatLng): L.LatLng{
    return L.latLng(
        latlng1.lat + latlng2.lat,
        latlng1.lng + latlng2.lng
    )
}

/**
 * Subtract latlng2 from latlng1
 */
export function diffLatLng(latlng1: L.LatLng, latlng2: L.LatLng): L.LatLng{
    return L.latLng(
        latlng1.lat - latlng2.lat,
        latlng1.lng - latlng2.lng
    )
}

/**
 * Returns the norm of a latlng
 */
export function lengthLatLng(latlng: L.LatLng): number{
    return Math.sqrt(
        Math.pow(latlng.lat, 2) + 
        Math.pow(latlng.lng, 2)
    )
}

/**
 * Takes two LatLngs and returns a vector of norm 1 in the direction from the first LatLng to the second.
 */
export function getUnitVector(latlng1: L.LatLng, latlng2: L.LatLng): L.LatLng {
    const differenceVector = diffLatLng(latlng2, latlng1);
    const normOfDifference = lengthLatLng(differenceVector);
    return L.latLng(
        differenceVector.lat / normOfDifference,
        differenceVector.lng / normOfDifference
    )
}

/**
 * Retrieve an array of all the country names and flags.
 */
export function getAllCountryNamesAndFlags(){
    return countryJson.features.map(country => {
        return {
            name: country.properties["ADMIN"],
            flag: getFlagEmoji(country.properties["ISO_A2"])
        }
    })
}
