import * as React from "react";
import {Button} from "src/components/button";
import {SpaceListener} from "src/components/spaceListener";

import "src/styles/style.css";
import "src/styles/form.css";

export interface GameEndProps {
	userNames: any;
	scores: any;
	restartGame: () => void;
	onChangeRoundView: (e) => void;
}
export function GameEndMultiView(props: GameEndProps) {
	const {userNames, scores, restartGame} = props;
	
	function onSubmitACB() {
		props.restartGame()
	}

	return (
        <main className="container " id="result-list">
            <h2>Results!</h2>
            {Object.keys(userNames).map((userId, index) => (
                <div className="result-list-item" key={userId}>
                    <div className="rank">#{index + 1}</div>
                    <div className="name">{userNames[userId]}</div>
                    <div className="muted-highlight">{scores[userId]} <span className="hide-on-mobile">points</span></div>
                </div>
            ))}
			<div className="align-center-element">
				<div className="button-wrapper-cool text-center mt-4">
					<Button className="btn btn-primary" onClick={onSubmitACB}>Return to Lobby</Button>
				</div>
			</div>
            <SpaceListener onSpacePressed={onSubmitACB} />
        </main>
    );
}
