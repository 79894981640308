export function stringToNumberHash(input: string): number {
	// Method stolen from here: https://stackoverflow.com/a/7616484/13347795
	let hash = 0;
	for (let i = 0; i < input.length; i++) {
		hash = ((hash << 5) - hash) + input.charCodeAt(i);;
		hash |= 0; // Convert to 32bit integer
	}
	return hash;
}
/** Hashes an id and returns a hue angle based on it.
 * @param id The id to be hashed.
 * @returns A hue angle between 0 and 360
 */
export function hueFromId(id: string): number {
	return stringToNumberHash(id) % 360;
}

// This is a really cool way to define a CSS color type, holy shit!
// From here: https://stackoverflow.com/a/71909826/13347795
/*export type RGB = `rgb(${number}, ${number}, ${number})`;
export type RGBA = `rgba(${number}, ${number}, ${number}, ${number})`;
export type HSL = `hsl(${number}, ${number}, ${number})`;
export type HSLA = `hsla(${number}, ${number}, ${number}, ${number})`;
export type HEX = `#${string}`;

export type Color = RGB | RGBA | HSL | HSLA | HEX;*/ // Also not used yet.
