import {User as FirebaseUser, createUserWithEmailAndPassword, signInWithEmailAndPassword, updateProfile, signOut } from "firebase/auth";
import { auth } from "src/modules/firebaseApi";
import { observable, action, computed, makeObservable } from "mobx";
import { PromiseHandler } from "src/model/promiseHandler";
import { Persistable } from "./persistable";

export class User implements Persistable {
	/** Copy of user object in Firebase. May be undefined. */
	firebaseUser?: FirebaseUser;
	/** Unique identifier for the user. (exactly the same as firebaseUser.uid) */
	get id(): string | null {return this.firebaseUser?.uid};
	/** Display name of the user. */
	name?: string;
	/** Highscore of the user. */
	highScore: number;
	/** Used to see if the model is ready to receive data from the database. */
	dbReady: boolean;

	constructor(firebaseUser: FirebaseUser) {
		makeObservable(this, {
			firebaseUser: observable,
			id: computed,
			name: observable,
			highScore: observable,
			dbReady: observable,

			updateUser: action,
			setData: action,
			setName: action,
			setReady: action,
		})
		this.updateUser(firebaseUser);
		this.setName(firebaseUser?.displayName);
	};

	/** Updates firebaseUser. */
	updateUser(user: FirebaseUser) {
		this.firebaseUser = user;
	};

	/** Updates data. */
	setData(input: number) {
		this.highScore = input;
	};

	/** Sets the name of the user. */
	setName(input: string) {
		this.name = input;
	}

	/** Sets the database (Firebase) transfer readiness of the user. */
	setReady(input: boolean) {
		this.dbReady = input;
	}

	/** Returns data from the model for the database. */
	modelToDb() {
		return { 
			name: this.name, 
			userData: Number(this.highScore),
		};
	}

	/** Sets the model data to data given by the database. */
	dbToModel(dbData: any) {
		this.setData(dbData?.userData ?? 10);
	}
}

export type UserModel = {
	/** Promise containing the current user. Use userHandler.result to get the Game object whenever the promise resolves. See {@link User} and {@link PromiseHandler}. */
	userHandler: PromiseHandler<User | null>,
	/** Creates a promise for a user object in {@link UserModel.user}.*/
	createUserPromise: Function,
	/** Creates a new user in the model using data from the database. */
	createUser: Function,
	/** Creates a new account. */
	createAccount: Function,
	/** Logs in to an account. */
	authenticateAccount: Function,
	/** Signs out of an account. */
	signOutOfAccount: Function
}

export const userModel: UserModel = {
	userHandler: new PromiseHandler(createUserPromise()),

	createUserPromise: function () { 
		this.userHandler.handlePromise(createUserPromise());
	},

	createAccount: async function (username: string, email: string, password: string) {
		if (username.length > 2) {
			return createUserWithEmailAndPassword(auth, email, password)
			.then((userCredential) => updateProfile(userCredential.user, {displayName: username}))
			.then(() => this.createUserPromise())
			.catch((error) => {
				console.log(error.message);
				if (error.message == "Firebase: Error (auth/invalid-email).") {
					return Promise.reject(new Error ("Please enter a vaild email address."));
				} else if (error.message = "Firebase: Error (auth/invalid-login-credentials).") {
					return Promise.reject(new Error ("Please enter a password longer 6 than characters."));
				} else {
					return error;
				}
			})
		} else {
			return Promise.reject(new Error ("Please enter a username longer than two characters."));
		}
	},

	createUser: async function (firebaseUser: FirebaseUser) {
		const user = new User(null);
		if (firebaseUser) {
			user.updateUser(firebaseUser);
			user.setName(user.firebaseUser.displayName);
		} else {
			user.updateUser(undefined);
		}
		return user;
	},

	authenticateAccount: async function (email: string, password: string) {
		return signInWithEmailAndPassword(auth, email, password)
		.then((promise) => {
			this.createUserPromise();
			return promise;
		})
		.catch((error) => {
			console.log(error.message);
			if (error.message == "Firebase: Error (auth/invalid-email).") {
				return Promise.reject(new Error ("Please enter a vaild email address."));
			} else if (error.message = "Firebase: Error (auth/invalid-login-credentials).") {
				return Promise.reject(new Error ("Incorrect email or password."));
			} else {
				return error;
			}
		});
	},

	signOutOfAccount: async function () {
		signOut(auth)
		.then((() => this.createUserPromise()));
	}
}

async function createUserPromise() {
	return new Promise<User>((resolve, reject) => {
		const unsubscribe = auth.onAuthStateChanged((currentUser) => {
			unsubscribe();
			currentUser ? resolve(userModel.createUser(currentUser)): resolve(null);
		}, reject);
	})	
}
